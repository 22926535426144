import ApplicationController from "./application_controller";
import SignaturePad from "signature_pad";

export default class extends ApplicationController {
  // static targets = ["canvas"];

  resizeCanvas(canvas) {
    let ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
  }

  connect() {
    let canvas = document.querySelector("canvas");
    if (canvas){
      canvas.height = canvas.offsetHeight;
      canvas.width = canvas.offsetWidth;
      window.onresize = this.resizeCanvas(canvas);
      this.resizeCanvas(canvas);
      let signature_pad = new SignaturePad(canvas);

      $('.signature_pad_clear').click(function() { signature_pad.clear() });

      $('.signature_pad_save').click(function(event) {
        if (signature_pad.isEmpty()){
          alert('You must add a signature to be able to sign documents.');
          event.preventDefault();
        } else {
          $('.signature_pad_input').val(signature_pad.toDataURL());
        }
      });
    }
  }
}
