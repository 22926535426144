import ApplicationController from "./application_controller";
// import BootstrapPincodeInput from "bootstrap-pincode-input";

export default class extends ApplicationController {
  static targets = ["deletable"];

  connect() {
    console.log("deletable")
  }
  delete(){
    this.deletableTarget.remove()
  }
}