import { Controller } from "stimulus";

export default class ShowHiddenController extends Controller {
  connect() {
    console.log("hidden showable start");
  }
  toggle() {
    console.log("hidden showable");
    $(".hidden-showable").toggleClass("hidden");
  }
}