import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["toggleable", "toggleButton", "untoggleButton"]
  connect() {
    console.log("toggle nested dashboard connected")
    // console.log(this.toggleButtonTarget)
    // console.log(this.untoggleButtonTarget)
    // console.log(this.toggleableTarget)
  }

  toggle() {
    console.log("toggle dashboard")
    $(this.toggleableTargets[0]).toggleClass("hidden");
    

    for (var i = 0; i <= 1; i++) {
      $(this.toggleButtonTargets[i]).toggleClass("hidden");
    }
    for (var i = 0; i <= 1; i++) {
      $(this.untoggleButtonTargets[i]).toggleClass("hidden");
    }
  }
}