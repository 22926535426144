import ApplicationController from "./application_controller";
// import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static targets = ["loadable", "file_upload", "file"]
  connect() {
    console.log("loader connected")
    // console.log(this.loadableTarget)
  }

  toggle(event) {
    // event.preventDefault();
    // Rails.fire(this.element, 'submit');
    console.log("fileTarget.files.length")
    console.log(this.fileTarget.files.length)
    if (this.fileTarget.files.length != 0) {
      console.log("if")
      this.loadableTarget.classList.add('evalii-outline-button-deactivated');
      this.loadableTarget.classList.remove('evalii-outline-button');
    } else {
      console.log("else")
      console.log(this.loadableTarget)
      this.loadableTarget.classList.add('evalii-outline-button');
      this.loadableTarget.classList.remove('evalii-outline-button-deactivated');
      this.loadableTarget.disabled = false;   
      var button = document.getElementById('loading-button');
      button.disabled = false;
      console.log("else end") 
      window.location.reload();
    }
    console.log("loading")
    window.location.reload();
  }
}