import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["page_number_input"]
  connect() {
    StimulusReflex.register(this);
    // this.add_change_listeners_to_all_targets();
    // console.log("paginator connected")
  }

  jump_to_page(event) {
    // console.log("jump")
    if (event.key === "Enter") {
      event.preventDefault();

      if (this.page_number_inputTarget.value !== "") {
        // var dataset = {"page": this.page_number_inputTarget.value}
        // console.log(JSON.stringify(dataset))
        this.stimulate("TicketsReflex#paginate", this.page_number_inputTarget.value);
      }
    }
  }
}