import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  // static targets = ["canvas"];

  connect() {
    $("#div_create_company").toggle();
  }

  toggle(){
    $("#div_create_company").fadeToggle();
  }
}
