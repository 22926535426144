import Velocity from "velocity-animate";
import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

export default class extends ApplicationController {
  static targets = [
    "title",
    "message",
    "pv_relevant",
    // "not_pv_relevant",
    "hwg_relevant",
    "status",
    "assignee",
    "pv_email_note",
    "ticket_id",
    "pin",
    "ticket_tags",
    "country",
    "case_numbers",
    "case_numbers_div",
    "moderation_needed",
    "template_type",

    "author_name",
    "author_age",
    "author_gender",
    "send_attachments",
    "last_translated_message",
    "last_translated_title",
    "send_only_to",
  ];
  // static targets = ["tab1", "tab2"];
  initialize() {
    this.selected_comments = [];
    // this.add_change_listeners_to_all_targets();

  }

  connect() {
    StimulusReflex.register(this);
    $("#email_field").hide();
    // console.log("ticket connected");
    this.add_change_listeners_to_all_targets();
    this.ticket_channel = consumer.subscriptions.create(
      {
        channel: "TicketChannel",
        ticket_id: this.ticket_idTarget.value
      },
      {
        received(data) {
          // console.log("TicketChannel data");
          // console.log(data);
          if (data.cableReady) CableReady.perform(data.operations);
        }
      }
    );
  }

  add_change_listeners_to_all_targets() {
    // console.log("add_change_listeners_to_all_targets");
    this.titleTarget.addEventListener("click", this.change_listener, false);
    this.messageTarget.addEventListener("click", this.change_listener, false) ;
    this.pv_relevantTarget.addEventListener("click", this.change_listener, false) ;
    this.hwg_relevantTarget.addEventListener("click", this.change_listener, false) ;
    this.statusTarget.addEventListener("click", this.change_listener, false) ;
    this.assigneeTarget.addEventListener("click", this.change_listener, false) ;
    this.ticket_idTarget.addEventListener("click", this.change_listener, false) ;
    this.pv_email_noteTarget.addEventListener("click", this.change_listener, false) ;
    // this.pinTarget.addEventListener("click", this.change_listener, false) ;
    
    

    this.author_nameTarget.addEventListener("click", this.change_listener, false);
    this.author_ageTarget.addEventListener("click", this.change_listener, false);
    this.author_genderTarget.addEventListener("click", this.change_listener, false);

    try {
      this.case_numbers_divTarget.addEventListener("click", this.change_listener, false);
      this.case_numbersTargets.forEach(case_number => { case_number.addEventListener("click", this.change_listener, false) })
      this.ticket_tagsTargets.forEach(ticket_tag => { ticket_tag.addEventListener("click", this.change_listener, false) });
      this.moderation_neededTarget.addEventListener("click", this.change_listener, false);
    } catch (error) {
      console.log(error)
    }
    
    try{
      this.last_translated_messageTarget.addEventListener("click", this.change_listener, false) ;
      this.last_translated_titleTarget.addEventListener("click", this.change_listener, false) ;
    } catch (error) { }

    try {
      this.case_numbersTarget.addEventListener("click", this.change_listener, false);
      this.ticket_tagsTarget.addEventListener("click", this.change_listener, false) ;
    } catch (error) { }

    this.countryTarget.addEventListener("click", this.change_listener, false) ;
  }

  change_listener() {
    console.log("click listener")
    $(".save-content-warning-js").show();
  }

  show_email_field() {
    this.generate_pv_email();
    $("#email_field").fadeIn(750);
  }
  
  toggle_pv_email_button(event){
    // if (event.target.tagName == "LABEL") {
    //   return
    // }
    // console.log("pv button change")
    // if (!($("#pv_email_button").is(":visible")) && $("#pv_relevant").is(":checked")) {
    //   // $("#pv_email_button").fadeIn(750);
    //   return
    // } else if (($("#pv_email_button").is(":visible")) && !($("#pv_relevant").is(":checked"))) {
    //   $("#pv_email_button").fadeOut(750);
    //   return
    // }
  }
  toggle_moderation_email_button(event){
    // if (event.target.tagName == "LABEL") {
    //   return
    // }

    // if (!($("#moderation_email_button").is(":visible")) && $("#moderation_needed").is(":checked")) {
    //   $("#moderation_email_button").fadeIn(750);
    //   return
    // } else if (($("#moderation_email_button").is(":visible")) && !($("#moderation_needed").is(":checked"))) {
    //   $("#moderation_email_button").fadeOut(750);
    //   return
    // }
  }

  toggle_email_field_if_pv_relevant(event) {
    // console.log("toggle_email_field_if_pv_relevant")
    // Ignorre LABEL event
    if (event.target.tagName == "LABEL") {
      // return
    }
    // console.log($("#email_field").is(":visible"))
    // console.log($("#pv_relevant").is(":checked"))
    if ( !($("#email_field").is(":visible")) && $("#pv_relevant").is(":checked")) {
      this.generate_pv_email("pv_mail_template");
      $(this.template_typeTarget).text("pv_mail_template");
      // $("#email_field").fadeIn(750);
      return
    } else if(($("#email_field").is(":visible")) )
    {
      $("#email_field").fadeOut(100);
      return
    }
  }


  toggle_email_field_if_moderation_needed(event) {
    if (event.target.tagName == "LABEL") {
      // return
    }

    if (!($("#email_field").is(":visible")) && $("#moderation_needed").is(":checked")) {
      $(this.template_typeTarget).text("moderation_mail_template");
      this.generate_pv_email("moderation_mail_template");
      // $("#email_field").fadeIn(750);
      return
    } else if (($("#email_field").is(":visible")) ) {
      $("#email_field").fadeOut(750);
      return
    }
  }
  hide_email_field() {
    $("#email_field").fadeOut(750);
  }

  selectComment(event) {
    console.log("word hider select");
    console.log(event);
    var elem = $("#" + event.target.id);
    console.log(elem);
    var comment = elem[0];
    console.log(comment.checked);
    console.log(comment.value);

    if (!comment.checked) {
      // elem.removeClass("ausblenden-highlight");
      this.removeComment(comment);
      $("#context_ticket_" + comment.value).fadeOut(750);

      // $("#comment_row_" + comment.value).appendTo("#comment_stream");
    } else {
      // elem.addClass("ausblenden-highlight");
      // $("#comment_row_" + comment.value).appendTo("#ticket_message");
      $("#context_ticket_" + comment.value).fadeIn(750);
      this.selected_comments.push(comment);
    }
    console.log(comment);
    console.log(this.selected_comments);
    this.generate_pv_email();
  }

  removeComment(comment) {
    for (var i = this.selected_comments.length - 1; i >= 0; i--) {
      if (this.selected_comments[i] == comment) {
        this.selected_comments.splice(i, 1);
      }
    }
  }

  generate_pv_email_if_visible() {
    console.log("generate_pv_email_if_visible")
    if ($("#email_field").is(":visible")){
      console.log("generating")
      this.generate_pv_email()
    }else {

    }
  }

  generate_pv_email(type) {
    // event.preventDefault();
    var ticket_params = {
      ticket: this.get_ticket(),
      template_type: type,
      context_tickets: this.get_context_tickets(),
      pv_email_note: this.pv_email_noteTarget.value,
    };
    console.log(this.selected_comments);
    console.log(ticket_params);
    this.stimulate(
      "TicketsReflex#generate_pv_email",
      JSON.stringify(ticket_params)
    ).then(() => {
      this.word_hiderController.connect();
      try {
        this.pinTarget.value= "";
      } catch (error) {
        console.log(error)
      }
      
      $("#email_field").fadeIn(750);
    })
  }

  insert_note(event) {
    event.preventDefault();
    $("#inserted_email_note").text(this.pv_email_noteTarget.value);
  }

  send_pv_email(event) {
    event.preventDefault();
    // Block UI
    $('.loader').css('display', 'block');

    console.log("this.template_typeTarget");
    console.log($(this.template_typeTarget).text());
    console.log("this.template_typeTarget");

    var ticket_params = {
      ticket: this.get_ticket(),
      context_tickets: this.get_context_tickets(),
      pin: this.pinTarget.value,
      pv_email_note: this.pv_email_noteTarget.value,
      template_type: $(this.template_typeTarget).text(),
      send_only_to: null ,
      send_attachments: this.send_attachmentsTarget.checked,
    };
    if (this.send_only_toTarget.value != "" && this.send_only_toTarget.value != null){
      ticket_params["send_only_to"] = this.send_only_toTarget.value
    }
    console.log(ticket_params)
    this.stimulate(
      "TicketsReflex#send_pv_email",
      JSON.stringify(ticket_params)
    ).then(() => this.jump_if_email_send());
  }

  jump_if_email_send() {

    if ($("#email_field").css("display") == "none") {
      window.scrollTo(0, 0);
      $("#save_content_warning").hide();
      $(".save-content-warning-js").hide();
    } else {

    }
  }

  get word_hiderController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("word-hider"),
      "word-hider"
    );
  }

  get_ticket() {
    var ticket_tags = []
    try {
      this.ticket_tagsTarget
      this.ticket_tagsTargets.forEach(ticket_tag => {
        if (ticket_tag.checked) {
          ticket_tags.push(ticket_tag.value)
        }
      }
      );
    } catch (error) {

    }

    let last_translated_title = "";
    let last_translated_message = "";
    let moderation_needed = false;
    
    try {
      moderation_needed = this.moderation_neededTarget.checked
    } catch (error) {

    }

    try {
     last_translated_title = this.last_translated_titleTarget.innerHTML;
     last_translated_message = this.last_translated_messageTarget.innerHTML;
    }catch(error){

    }


    var case_numbers = []
    console.log(
      )
    var inputs = $(".case_numbers_input")
    var lenght = inputs.length

    for (let index = 0; index < lenght; index++) {
      case_numbers.push(inputs[index].value);

    }
    return {
      title: this.titleTarget.innerHTML,
      last_translated_title: last_translated_title,
      last_translated_message: last_translated_message,
      message: this.messageTarget.innerHTML,
      pv_relevant: this.pv_relevantTarget.checked,
      moderation_needed: moderation_needed,
      hwg_relevant: this.hwg_relevantTarget.checked,
      status: this.statusTarget.value,
      assignee_id: this.assigneeTarget.value,
      ticket_id: this.ticket_idTarget.value,
      pv_email_note: this.pv_email_noteTarget.value,
      pin: this.pinTarget.value,
      ticket_tags: ticket_tags,
      country_id: this.countryTarget.value,
      case_numbers: case_numbers,
      author_name:  this.author_nameTarget.value,
      author_age:  this.author_ageTarget.value,
      author_gender:   this.author_genderTarget.value,
    };
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  update_ticket() {
    event.preventDefault();
    console.log(this.titleTarget.innerHTML);
    console.log(this.messageTarget.innerHTML);
    try {
      console.log(this.last_translated_messageTarget.innerHTML);
      console.log(this.last_translated_titleTarget.innerHTML);
    }catch (error){ }

    console.log(this.pv_relevantTarget.checked);
    // console.log(this.not_pv_relevantTarget.checked);
    console.log(this.hwg_relevantTarget.checked);

    console.log(this.statusTarget.value);
    console.log(this.assigneeTarget.value);
    console.log("Country:");
    console.log(this.countryTarget.value);
    console.log(this.ticket_idTarget.value);
    // console.log(this.case_numberTarget.values);
    // console.log(this.ticket_tagsTargets.value);
    // $("#save_content_warning").hide();
    var ticket = this.get_ticket();
    console.log(ticket);
    this.stimulate("TicketsReflex#update_ticket", JSON.stringify(ticket));
    this.word_hiderController.connect();
    $("div.success").fadeIn(300).delay(2000).fadeOut(300)
    
    
    if (!this.pv_relevantTarget.checked && !this.moderation_neededTarget.checked) {
      setTimeout(function () { window.scrollTo({ top: 0, behavior: 'smooth' });}, 1000)
    }

    $(".save-content-warning-js").hide();
    // setTimeout(function () { $(".evalii-ticket-container").css("background-color", "#8cad2d") }, 500)
    // setTimeout(function () { $(".evalii-ticket-container").css("background-color", "white") }, 3000)
    // setTimeout(() => { console.log(""); }, 2000);
  }

  get_context_tickets() {
    var context_tickets = [];
    this.selected_comments.forEach(comment =>
      context_tickets.push([
        $("#context_title_" + comment.value).html(),
        $("#context_message_" + comment.value).html(),
        comment.value
      ])
    );
    return context_tickets
  }
  add_case_number() {
    console.log(this.case_numbers_divTarget)
    // console.log(this.case_numbers_divTarget.methods())
    this.case_numbers_divTarget.insertAdjacentHTML('beforeend',
    '<div class="row" data-controller="delete-element" data-target="delete-element.deletable"> ' +
    '<input class= "mt-2 ml-3 case_numbers_input" type = "textbox" data - target="ticket.case_numbers", value = "" >'+
    '  <a class="case_numbers_trash_icon_link" data-action="click->delete-element#delete"> '+
    '    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" '+
    '      viewBox="0 0 16 16"> '+
    '      <path '+
    '        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" /> '+
    '      <path fill-rule="evenodd" '+
    '        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" /> '+
    '    </svg> '+
    '  </a> '+
    '  </div > '
    )
  }
}
