import Velocity from "velocity-animate";
import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

export default class extends ApplicationController {
  static targets = ["container", "input"]
  connect() {
    console.log("array input connected")
    console.log(this.inputTargets)
    console.log(this.containerTarget)
  }

  add_input(){
    event.preventDefault()
    console.log(this.inputTargets[0])
    console.log($(this.inputTargets[0]))
    var inputs_length = this.inputTargets.length
    var lastField = $(this.inputTargets[0]).clone()
    lastField.addClass("mt-2")
    $(this.containerTarget).append(lastField)
    console.log(lastField)
    $(lastField).find("input")[0].value = ""
  }
  remove_input(event){
    this.inputTargets[this.inputTargets]
    console.log("######")
    console.log(this.inputTargets.length)
    console.log($(event.target).parent().parent()[0].nodeName)
    if (this.inputTargets.length <= 1){
      return
    }
    $(event.currentTarget).parent().remove();
  }
}