import { Controller } from "stimulus";
// import StimulusReflex from "stimulus_reflex";

export default class NotificationModalController extends Controller {
  static targets = [];
  connect() {
    console.log("Hello, Stimulus!");
  }

  close(event) {
    $(".close").click();
  }
}
