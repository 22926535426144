import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  // static targets = ["output", "text"];
  static targets = ["text", "ticket_id"];
  connect() {
    console.log("ticket comment controller");
    console.log(`Hello, ${this.textTarget.value}!`);
    StimulusReflex.register(this);
  }

  send_ticket_comment(event) {
    console.log(event.key);
    if (event.key === "Enter") {
      // in prevent form action
      event.preventDefault();
      // ignorre empty searches
      if (this.textTarget.value !== "") {
        this.stimulate(
          "TicketsReflex#add_comment",
          this.textTarget.value,
          this.ticket_idTarget.value
        );
        this.textTarget.value = "";
      }
    }
    // console.log(event.key === "Enter");
    // console.log(`Hello, ${this.search_termTarget.value}!`);
  }
}
