import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import ApplicationController from "./application_controller";
// import consumer from "../channels/consumer";

export default class extends ApplicationController {
  static targets = ["search_term", "search_url", "search_url_port", "status", "project", "assignee", "channelable_type", "tag", "country", "patient_status" ];
  connect() {
    StimulusReflex.register(this);
  }

  send_search_term(event) {
    if (event.key === "Enter") {
      // in prevent form action
      event.preventDefault();
      // ignorre empty searches
      if (this.search_termTarget.value !== "") {
        // console.log(this.search_urlTarget.value);
        var url = new URL(this.search_urlTarget.value);
        url.searchParams.set("search_term", this.search_termTarget.value);
        // url.port = this.search_url_portTarget.value;
        // console.log(url);
        window.location.href = url;
      }
    }
  }
  change_value(event) {
    this.stimulate(
      "TicketsReflex#search",
      JSON.stringify(this.get_search_dropdowns())
    );
  }

  get_search_dropdowns() {
    return {
      status: this.statusTarget.value,
      project_id: this.projectTarget.value,
      assignee_id: this.assigneeTarget.value,
      channelable_type: this.channelable_typeTarget.value,
      tag_id: this.tagTarget.value,
      country_id: this.countryTarget.value,
      patient_status: this.patient_statusTarget.value,
    }
  }
}
