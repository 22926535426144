

import ApplicationController from "./application_controller";
const axios = require("axios");

export default class extends ApplicationController {
  static targets = ["upload_field", "response_field", "csrf_token_upload"]

  upload(event) {
    event.preventDefault();
    let formData = new FormData();
    let file = this.upload_fieldTarget.files[0];

    formData.append("file", file);
    // formData.append("user", JSON.stringify(user));

    const ctrl = new AbortController()    // timeout
    setTimeout(() => ctrl.abort(), 5000);
    // console.log(this.csrf_token_uploadTarget)
    // console.log(this.csrf_token_uploadTarget.value)
    axios
      .post('/case_numbers', formData,{
        headers: {
          "X-CSRF-Token": this.csrf_token_uploadTarget.value,
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        // console.log(response)
        var response_html = ""

        for (let index = 0; index < response.data.length; index++) {
          response_html += "<div class='row'>" + response.data[index][0] + "," + response.data[index][1] +  "</div>"
        }

        
        $("#response_field").html(response_html)
      });

  }
}
