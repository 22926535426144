import { Controller } from "stimulus";
// import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["menu", "button"];
  toggle(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.buttonTarget.getAttribute("aria-expanded") == "false") {
      this.show();
    } else {
      this.hide(event);
    }
  }

  show() {
    this.buttonTarget.setAttribute("aria-expanded", "true");
    this.menuTarget.hidden = false;
  }

  hide(event) {
    if (event.target == this.menuTarget) {
      event.preventDefault();
      return;
    }
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.buttonTarget.hidden = false;
    this.menuTarget.hidden = true;
  }
}
