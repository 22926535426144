import ApplicationController from "./application_controller";
// import BootstrapPincodeInput from "bootstrap-pincode-input";

export default class extends ApplicationController {
  // static targets = ["canvas"];

  connect() {

    Array.from(document.getElementsByClassName('pin-inputs')).map(pin => {
      pin.maxLength = 1;
      pin.onFocus = "this.value=''";
      pin.autoComplete = 'off';
    });

    $(".pin-inputs").keyup(function () {
      if (this.value.length == this.maxLength) {
        $(this).next('.pin-inputs').focus();
      }
    });
  }
}
