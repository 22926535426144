import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["toggleable", "toggleButton", "untoggleButton"]

  // static values = {
  //   tab: Number
  // }

  connect() {
    console.log("toggle dashboard connected")
    // console.log(this.toggleButtonTarget)
    // console.log(this.untoggleButtonTarget)
    // console.log(this.toggleableTarget)
  }

  toggle(event) {
    // console.log("toggle dashboard")
    const tab = parseInt(event.target.dataset.dashboardTab);
    // console.log(event.target.dataset.dashboardTab)


    for (var i = 0; i < this.toggleButtonTargets.length; i++) {
      if (i == tab) {
        $(this.toggleableTargets[i]).removeClass("hidden");
      }else {
        $(this.toggleableTargets[i]).addClass("hidden");
      }
       
    }
    for (var i = 0; i < this.toggleButtonTargets.length; i++) {
      if (i == tab) {
        $(this.toggleButtonTargets[i]).addClass("active");
      }else {
        $(this.toggleButtonTargets[i]).removeClass("active");
      }
    }
  }
}