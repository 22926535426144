// if (this.hasResultsTarget) {
//   this.resultsTarget.innerHTML = "…"
// }

import Velocity from "velocity-animate";
import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

export default class extends ApplicationController {
  static targets = ["projects", "channels", "companies", "ticket_tags", "statuses", "has_case_number", "pv_relevant", "hwg_relevant", "start_date", "end_date", "page", "manually_created", "search_term", "loading_animation"]
  connect() {
    StimulusReflex.register(this);
    // this.add_change_listeners_to_all_targets();
    // console.log("filter connected")
  }
  // add_change_listeners_to_all_targets() {
  //   // this.titleTarget.addEventListener("click", this.change_listener, false);
  //   try {
  //     // this.case_numbersTarget.addEventListener("click", this.change_listener, false);
  //   } catch(error) {
  //   }
  // }
  send_search_term(event) {
    if (event.key === "Enter") {
      // in prevent form action
      event.preventDefault();
      // ignorre empty searches
      // if (this.search_termTarget.value !== "") {
        this.send_filters();
      // }
    }
  }

  send_filters() {
    history.replaceState(null, "", location.href.split("?")[0]);
    var filters = this.get_all_filters()
    console.log(filters)
    $(this.loading_animationTarget).show()
    this.stimulate(
      "TicketsReflex#set_filters",
      JSON.stringify(filters)
    );
  }

  get_empty_filter() {
    return {
      companies: [],
      projects: [],
      channels: [],
      ticket_tags: [],
      statuses: [],
      conditionals: [],
      start_date: "",
      end_date: "",
      page: 1,
      search_term: "",
    }
  }

  get_all_filters() {
    var filters = this.get_empty_filter()
    console.log(this.projectsTargets)

    for (let index = 0; index < this.companiesTargets.length; index++) {
      const company = this.companiesTargets[index];
      if (JSON.parse(company.getAttribute("data-active").toLowerCase())) {
        filters["companies"].push(company.getAttribute("data-id"))
      }

    }

    for (let index = 0; index < this.projectsTargets.length; index++) {
      const project = this.projectsTargets[index];
      if (JSON.parse(project.getAttribute("data-active").toLowerCase())){
        filters["projects"].push(project.getAttribute("data-id"))
      }
      
    }

    for (let index = 0; index < this.channelsTargets.length; index++) {
      const channel = this.channelsTargets[index];
      if (JSON.parse(channel.getAttribute("data-active").toLowerCase())) {
        filters["channels"].push(channel.getAttribute("data-id"))
      }

    }

    for (let index = 0; index < this.ticket_tagsTargets.length; index++) {
      const ticket_tag = this.ticket_tagsTargets[index];
      if (JSON.parse(ticket_tag.getAttribute("data-active").toLowerCase())) {
        filters["ticket_tags"].push(ticket_tag.getAttribute("data-id"))
      }

    }
    
    for (let index = 0; index < this.statusesTargets.length; index++) {
      const ticket_tag = this.statusesTargets[index];
      if (JSON.parse(ticket_tag.getAttribute("data-active").toLowerCase())) {
        filters["statuses"].push(ticket_tag.getAttribute("data-id"))
      }

    }

    try {
      if (JSON.parse(this.has_case_numberTarget.getAttribute("data-active").toLowerCase())) {
        filters["conditionals"].push(this.has_case_numberTarget.getAttribute("data-id"))
      }
      if (JSON.parse(this.pv_relevantTarget.getAttribute("data-active").toLowerCase())) {
        filters["conditionals"].push(this.pv_relevantTarget.getAttribute("data-id"))
      }
      if (JSON.parse(this.hwg_relevantTarget.getAttribute("data-active").toLowerCase())) {
        filters["conditionals"].push(this.hwg_relevantTarget.getAttribute("data-id"))
      }
      if (JSON.parse(this.manually_createdTarget.getAttribute("data-active").toLowerCase())) {
        filters["conditionals"].push(this.manually_createdTarget.getAttribute("data-id"))
      }

    } catch {
      
    }
    // console.log(this.end_dateTarget.value)
    filters["start_date"] = (this.start_dateTarget.value)
    filters["end_date"] = (this.end_dateTarget.value)
    filters["page"] = (this.pageTarget.getAttribute("data-id"))
    filters["search_term"] = this.search_termTarget.value

    return filters
  }

  filter_by_element(event) {
    event.preventDefault();
    console.log(event.target.getAttribute("data-element-type"));
    console.log(event.target.getAttribute("data-id"));
    console.log(event.target.getAttribute("data-active"));
    console.log(this.projectsTargets)
    // $(event.target).toggleClass("evalii-filter-button-active");
    // $(event.target).toggleClass("evalii-filter-button");
    var element_active = JSON.parse(event.target.getAttribute("data-active").toLowerCase());
    event.target.setAttribute("data-active", !element_active);
    $(event.target).toggleClass("evalii-filter-button-active");
    console.log(event.target.getAttribute("data-active"));
    console.log(this.projectsTargets)
    // console.log(event.target.getAttribute("data-active"));
    this.send_filters()
  }
}