import Velocity from "velocity-animate";
import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";

import consumer from "../../channels/consumer";
import CableReady from "cable_ready";

export default class extends ApplicationController {
  static targets = [
    "current_ticket_id",
    "ticket_id",
    "success",
    "failure",
    "form_title",
    "form_message",
  ];
  // static targets = ["tab1", "tab2"];

  connect() {
    StimulusReflex.register(this);

    console.log("conversation connected");
    // this.ticket_channel = consumer.subscriptions.create(
    //   {
    //     channel: "TicketChannel",
    //     ticket_id: this.ticket_idTarget.value
    //   },
    //   {
    //     received(data) {
    //       // console.log("TicketChannel data");
    //       // console.log(data);
    //       if (data.cableReady) CableReady.perform(data.operations);
    //     }
    //   }
    // );
  }

  handle_error(payload) {
    // console.log(payload)
    $("#ticket-connection-failure").fadeIn(300).fadeOut(1500);
  }

  add_conversation(event) {
    console.log(event.params)
    $('.loader').css('display', 'block');

    if (event.params.currentTicketId && event.params.ticketId) {
      console.log(event.params.currentTicketId)
      this.stimulate("ConversationReflex#add_conversation", event.params.currentTicketId, event.params.ticketId).then(payload => {
        $('.loader').css('display', 'none');
        $(this.successTarget).fadeIn(500).fadeOut(1700);
      }).catch(payload => {
        $('.loader').css('display', 'none');
        $(this.failureTarget).fadeIn(500).fadeOut(1700);
      });
      return;
    }
    console.log(this.ticket_idTarget)
    if (this.ticket_idTarget && this.ticket_idTarget.value !== "") {
      console.log(this.ticket_idTarget.value)
      // var dataset = {"page": this.page_number_inputTarget.value}
      // console.log(JSON.stringify(dataset))
      this.stimulate("ConversationReflex#add_conversation", this.current_ticket_idTarget.value, this.ticket_idTarget.value).then(payload => {
        $('.loader').css('display', 'none');
        $(this.successTarget).fadeIn(500).fadeOut(1700);
      }).catch(payload => {
        $('.loader').css('display', 'none');
        $(this.failureTarget).fadeIn(500).fadeOut(1700);
      });
    }else {
      $('.loader').css('display', 'none');
      $(this.failureTarget).fadeIn(500).fadeOut(1700);
    }
  }
  
  
  clear_input() {

    // this.ticket_idTarget.value = " "
    this.form_titleTarget.value = "" 
    this.form_messageTarget.value = ""

  }

  clear_input_ticked_id() {
    this.ticket_idTarget.value = ""
  }

  delete_conversation(event) {
    // console.log(event)
    // console.log(event.params)
    // console.log(event.params.id)
    if (event.params.id !== "" ) {
      this.stimulate("ConversationReflex#delete_conversation", event.params.id);
    }
  }

  create_manual_conversation(event) {
    // console.log(event)
    // console.log(event.params)
    console.log(event.params.id)
    $('.loader').css('display', 'block');
    // console.log([this.ticket_idTarget.value, this.form_titleTarget.value, this.form_messageTarget.value])
    if (event.params.id !== "") {
      this.stimulate("ConversationReflex#create_manual_conversation", this.ticket_idTarget.value, this.form_titleTarget.value, this.form_messageTarget.value).then(payload => {
        $('.loader').css('display', 'none');
        $(this.successTarget).fadeIn(500).fadeOut(1700);
        this.clear_input();
      }).catch(payload => {
        $(this.failureTarget).fadeIn(500).fadeOut(1700);
        $('.loader').css('display', 'none');
      });
    ;
    }
  }

}