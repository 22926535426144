import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  // static targets = ["output", "text"];
  static targets = ["buttonLeft","buttonRight", "scrollable"];
  connect() {

  }

  scrollLeft(event){
    // console.log("ticket index scrollLeft");
    // console.log($(".scrolling-wrapper"))
    this.buttonLeftTarget.classList.remove('text-dark-blue');

    $(this.scrollableTarget).animate(
      {
        scrollLeft: "-=300px"
      },
      "slow",
      ()=>{
        this.buttonLeftTarget.classList.add('text-dark-blue');
      }
      );
    }
  scrollRight(event){
    // console.log("ticket index scrollRight");
    // console.log($(".scrolling-wrapper"))
    // event.preventDefault();
    this.buttonRightTarget.classList.remove('text-dark-blue');

    $(this.scrollableTarget).animate(
      {
        scrollLeft: "+=300px"
      },
      "slow",
    ()=>{
      this.buttonRightTarget.classList.add('text-dark-blue');
    }
    );
  }


}
