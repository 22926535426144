import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["toggleable", "toggleButton", "untoggleButton"]
  connect() {
    // console.log("toggle connected")
    // console.log(this.toggleButtonTarget)
    // console.log(this.untoggleButtonTarget)
    // console.log(this.toggleableTarget)
  }

  toggle() {
    $(this.toggleableTarget).toggle()
    $(this.toggleButtonTarget).toggle()
    $(this.untoggleButtonTarget).toggle()
  }
}