import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  initialize() {
    // We need to know when there are new messages that have been created by other users
    consumer.subscriptions.create(
      { channel: "NotificationsChannel" },
      {
        received: this._cableReceived.bind(this)
      }
    );

    consumer.subscriptions.create(
      { channel: "updates" },
      {
        received: this._cableReceived.bind(this)
      }
    );
  }

  _cableReceived() {
    this.stimulate("Notifications#update_messages");
  }
}
