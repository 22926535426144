import { Controller } from "stimulus";
const axios = require("axios");


class ApiStatusController extends Controller {
  static targets = ["api_status_text", "api_status_button"];

  connect() {
    // Both log the same elements
    setTimeout(function() {
      // console.log(this.itemTargets);
    }, 1);
    // console.log(this.itemTargets);
    // console.log(this.element.querySelectorAll("[data-target='hello.item']"));
    // console.log("connect");
  }

  hello() {
    // console.log($("input#authenticity_token").value);
    // console.log($("#authenticity_token").value);
    // console.log(document.getElementById("authenticity_token").value);
    // console.log(document.getElementsByName("authenticity_token")[0].content);

    this.button_text = "Loading APIs...";
    axios
      .get("/api_status", {
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": document.getElementById("authenticity_token").value,
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        this.button_text = "Test APIs";
        this.message = response.data;
        // console.log("sss");
        // console.log(response.data);
        //status.aws.amazon.com/
        https: this.api_status_textTarget.insertAdjacentHTML(
          "beforeend",
          '<p><a href="http://status.aws.amazon.com">Amazon Status Link</a></p>' +
            '<p style="color: ' +
            response.data.twitter_status_color +
            '">Twitter ' +
            response.data.twitter_status +
            "</p>" +
            '<p style="color: ' +
            response.data.facebook_status_color +
            '">' +
            response.data.facebook_status +
            "</p>" +
            '<p style="color: ' +
            response.data.youtube_status_color +
            '">' +
            response.data.youtube_status +
            "</p>"
        );
      });
  }
}

export default ApiStatusController;
