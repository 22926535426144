import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = [];
  connect() {
    StimulusReflex.register(this);
    console.log("word hider connected");
    // this.tab = "tab1"
    this.selected_words = [];
    this.description = $("#ticket_message");
    this.words = $("#ticket_message");
    console.log(this.description);
    console.log(this.words);
  }

  test() {
    console.log("test");
  }

  selectText(event) {
    // console.log("word hider select");
    console.dir(event.target);
    var elem = $("#" + event.target.id);
    // console.log(elem);
    // console.log(event);
    var word = elem[0];
    if (elem.hasClass("ausblenden-highlight")) {
      elem.removeClass("ausblenden-highlight");
      this.removeWord(word);

      var other_occurences = $(".words_box:contains(" + elem.text() + ")")
      // other_occurences.removeClass("ausblenden-highlight")
      // for (var i = other_occurences.length - 1; i >= 0; i--) {
      //   this.removeWord(other_occurences[i]);
      // }
    } else {
      elem.addClass("ausblenden-highlight");
      this.selected_words.push(word);

      var other_occurences = $(".words_box:contains(" + elem.text() + ")")
      
      for (var i = other_occurences.length - 1; i >= 0; i--) {
        // console.log(other_occurences[i].textContent)
        if (elem.text() == other_occurences[i].textContent) {
          this.selected_words.push(other_occurences[i]);
          other_occurences[i].classList.add("ausblenden-highlight")
        }
      }
    }
    // console.log(word);
    // console.log(this.selected_words);
  }

  removeWord(word) {
    for (var i = this.selected_words.length - 1; i >= 0; i--) {
      if (this.selected_words[i] == word) {
        this.selected_words.splice(i, 1);
      }
    }
  }

  nameReplace() {
    // console.log('replace...');
    // var description_sub = this.description.value;
    var i;
    this.selected_words.forEach(function(selected_word) {
      // console.log(selected_word.id);
      // console.log($("#" + selected_word.id)[0].text)
      $("#" + selected_word.id)[0].innerText = "XXX";
      // console.log($("#" + selected_word.id)[0].text)
      // $("#" + selected_word.id)[0].outerHtml = "XXX&nbsp;";
    });
    // for (i in this.selected_words) {
    //   description_sub = description_sub.replace(this.selected_words[i], "XXX");
    // }

    // description.value = description_sub;
  }
}
